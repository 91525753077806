import React, { useEffect } from "react";

export const DownArrow = (props) => {
  useEffect(() => {
    // console.log(props.fillColor);
  }, [props]);
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 590.00964 472.784"
      width="590.00964"
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs73" />
      <g
        id="g38"
        style={{ fill: "#800000" }}
        transform="translate(86.20779,-46.978508)"
      >
        <g
          id="g6-2"
          style={{ fill: props.fillColor }}
          transform="matrix(-1,0,0,1,503.79221,38.370508)"
        >
          <g id="g4-9" style={{ fill: props.fillColor }}>
            <path
              d="m 516.25,214.562 -221.25361,180.953 -0.001,-59.287 C 368.74758,275.81014 442.49862,215.39085 516.25,154.972 v 59.59 M 590,240.122 V 8.608 L 294.99518,250.28 v 231.112 z"
              id="path2-7"
              style={{ fill: props.fillColor, strokeWidth: 1.09731 }}
            />
          </g>
        </g>
        <g
          id="g6-2-8"
          style={{ fill: props.fillColor }}
          transform="translate(-86.19815,38.370508)"
        >
          <g id="g4-9-2" style={{ fill: props.fillColor }}>
            <path
              d="m 516.25,214.562 -221.25361,180.953 -0.001,-59.287 C 368.74758,275.81014 442.49862,215.39085 516.25,154.972 v 59.59 M 590,240.122 V 8.608 L 294.99518,250.28 v 231.112 z"
              id="path2-7-5"
              style={{ fill: props.fillColor, strokeWidth: 1.09731 }}
            />
          </g>
        </g>
        <g id="g8" style={{ fill: "#800000" }}></g>
        <g id="g10" style={{ fill: "#800000" }}></g>
        <g id="g12" style={{ fill: "#800000" }}></g>
        <g id="g14" style={{ fill: "#800000" }}></g>
        <g id="g16" style={{ fill: "#800000" }}></g>
        <g id="g18" style={{ fill: "#800000" }}></g>
        <g id="g20" style={{ fill: "#800000" }}></g>
        <g id="g22" style={{ fill: "#800000" }}></g>
        <g id="g24" style={{ fill: "#800000" }}></g>
        <g id="g26" style={{ fill: "#800000" }}></g>
        <g id="g28" style={{ fill: "#800000" }}></g>
        <g id="g30" style={{ fill: "#800000" }}></g>
        <g id="g32" style={{ fill: "#800000" }}></g>
        <g id="g34" style={{ fill: "#800000" }}></g>
        <g id="g36" style={{ fill: "#800000" }}></g>
      </g>
      <g id="g40" transform="translate(86.20779,-46.978508)"></g>
      <g id="g42" transform="translate(86.20779,-46.978508)"></g>
      <g id="g44" transform="translate(86.20779,-46.978508)"></g>
      <g id="g46" transform="translate(86.20779,-46.978508)"></g>
      <g id="g48" transform="translate(86.20779,-46.978508)"></g>
      <g id="g50" transform="translate(86.20779,-46.978508)"></g>
      <g id="g52" transform="translate(86.20779,-46.978508)"></g>
      <g id="g54" transform="translate(86.20779,-46.978508)"></g>
      <g id="g56" transform="translate(86.20779,-46.978508)"></g>
      <g id="g58" transform="translate(86.20779,-46.978508)"></g>
      <g id="g60" transform="translate(86.20779,-46.978508)"></g>
      <g id="g62" transform="translate(86.20779,-46.978508)"></g>
      <g id="g64" transform="translate(86.20779,-46.978508)"></g>
      <g id="g66" transform="translate(86.20779,-46.978508)"></g>
      <g id="g68" transform="translate(86.20779,-46.978508)"></g>
    </svg>
  );
};
