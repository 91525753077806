import React from "react";

export default function Contact() {
  return (
    <div className="contact-wrapper">
      <div className="details-container">
        <div className="details">
          <p>
            <span className="label">Phone:</span>
            <a href="tel:587-438-1504">587-438-1504</a>
          </p>
          <p>
            {" "}
            <span className="label">Email:</span>
            <a href="mailto:info@saponuksustainability.com">
              info@saponuksustainability.com
            </a>
          </p>
          <p id="site">https://saponuksustainability.com</p>
          <p>
            We are located on the Treaty Lands and Territory of the Mississaugas
            of the Credit and the traditional territory of the Anishinaabe, the
            Huron-Wendat, the Haudenosaunee, and the Chippewa.
          </p>
          <p>
            Today, the meeting place of Toronto (also known as Tkaronto) is home
            to many Indigenous people from across Turtle Island.
          </p>
        </div>
      </div>
    </div>
  );
}
